/* eslint-disable react/prop-types */
import React from 'react';
import { graphql } from 'gatsby';
import styled from '@emotion/styled';

import PageSEO from '@zd/components/page-seo';
import { Container } from '@zd/components/grid';
import HtmlContent from '@zd/components/html-content';
import PageTitle from '@zd/components/page-title';
import BackLink from '@zd/components/back-link';

export const query = graphql`
  query($slug: String!) {
    markdownRemark(fields: { sourceName: { eq: "legal-pages" }, slug: { eq: $slug } }) {
      excerpt(pruneLength: 250)
      html
      frontmatter {
        title
      }
    }
  }
`;

export default props => {
  const legalPage = props.data.markdownRemark;
  const title = legalPage.frontmatter.title;
  const html = legalPage.html;
  const excerpt = legalPage.excerpt;

  return (
    <>
      <PageSEO {...props} title={title} description={excerpt} />
      <Container>
        <PageTitle title={title} />
        <HtmlContent html={html} />
        <BackLink />
      </Container>
    </>
  );
};
